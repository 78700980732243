import CpfValidate from './cpf'
const validator = {
  getMessage () { // will be added to default English messages.
    return 'CPF inválido'
  },
  validate (value) {
    return CpfValidate(value)
  }
}
export default validator
