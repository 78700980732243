export default function(value){
  if(value != null && value != ''){
    if(value.toString().indexOf(",") >= 0){
      return value
    }else{
      return value+',00'
    }
  }else{
    return ''
  }
}