export default function (
  event,
  digits = 2,
  max_value = true
) {
  if (event != null && event != '') {
    var valueData = null;
    if (typeof event.target == 'undefined') {
      valueData = event;
    } else {
      valueData = event.target.value;
    }

    var value = valueData;
    var isDecimal = false;
    var result = '';
    var divisor = 3;
    var lastValue = null;
    var caracter = '';

    if (value.toString().indexOf(',') >= 0) {
      isDecimal = true;
    }

    var valueOriginal = value;
    value = value
      .trim()
      .replaceAll('.', '')
      .replaceAll(',', '')
      .replaceAll('-', '');
    var countString = value.length;

    if (valueOriginal.indexOf('-') >= 0) {
      caracter = '-';
    }

    if (isDecimal) {
      lastValue = valueOriginal.split(',')[1];
      value = valueOriginal
        .split(',')[0]
        .trim()
        .replaceAll('.', '')
        .replaceAll(',', '')
        .replaceAll('-', '');
      countString = value.length;
    }

    for (var i = 0; i < countString; i++) {
      if (i == 0) {
        result = value.slice(-1) + result.toString();
      } else {
        if (i > 0) {
          if (i % divisor == 0) {
            result =
              value.slice(-(i + 1), -i) +
              '.' +
              result.toString();
            divisor = 3;
          } else {
            result =
              value.slice(-(i + 1), -i) + result.toString();
          }
        } else {
          result =
            value.slice(-(i + 1), -i) + result.toString();
        }
      }
    }
    if (isDecimal) {
      result =
        result.toString() +
        ',' +
        lastValue.replaceAll('.', '').substring(0, digits);
    }

    if (max_value) {
      // if (result.match(/^\d{0,3}(?:[.,]\d{0,6})?$/)) {
      //   result;
      // } 
      // else {
      //   result = '999,00';
      // }
    }

    return (
      caracter +
      result.toString().replaceAll(/[^0-9.,,]/g, '')
    );
  } else {
    return '';
  }
}
