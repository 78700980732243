import CnpjValidate from './cnpj'
const validator = {
  getMessage () { // will be added to default English messages.
    return 'CNPJ inválido'
  },
  validate (value) {
    return CnpjValidate(value)
  }
}
export default validator
