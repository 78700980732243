import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import Swal from 'sweetalert2/dist/sweetalert2.js'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
   storage: window.localStorage,
   key:'sknet_store'
})

const store = new Vuex.Store({
    state: {
        user: null,
        access: null,
        theme:'white'
    },
    mutations: {
        setTheme(state, theme) {
            state.theme = theme
        },
        setUser(state, user) {
            state.user = user
        },
        setAccess(state, access) {
            state.access = access
        }
    },
    actions: {
        setTheme(context,theme) {
            context.commit('setTheme',theme)
        },
        setUser(context,user) {
            context.commit('setUser',user)
        },
        setAccess(context, access) {
            context.commit('setAccess',access)
        },
        isLoading(){
            Swal.fire(
                {
                    position: 'bottom-end',
                    title:'Aguarde',
                    text:'Processo em Andamento',
                    allowOutsideClick:false,
                    didOpen:()=>{Swal.showLoading()}
                })
        },
        errorMessage(context,error){
            Swal.close()
            if(typeof error.message != 'undefined'){
                Swal.fire({title: 'Error!',text: error.message,icon: 'error',confirmButtonText: 'OK'})
            }
           
        },
        errorMessageAll(context,error){
            Swal.close()
            if(error != '' || error != null){
                Swal.fire({title: 'Error!',text: error,icon: 'error',confirmButtonText: 'OK'})
            }
           
        },
        errorMessageClear(context,error){
            Swal.close()
            if(error != '' || error != null){
                Swal.fire({title: error,icon: 'error',confirmButtonText: 'OK'})
            }
           
        },
        successMessage(context,message){
            Swal.close()
            Swal.fire(
                {
                    position: 'bottom-end',
                    text:message,
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false,
                }
            )
        },
        closeLoading(){
            Swal.close()
        }
    },
    getters: {
        theme(state) {
            return state.theme
        },
        getUser(state) {
            return state.user
        },
        getAccess(state) {
            return state.access
        }
    },
    plugins: [vuexLocal.plugin]
})

export default store