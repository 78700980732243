<template>
  <div id="app" class="bg-background">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'App'
}
</script>
<style lang="scss">
@import './assets/css/global.scss';
</style>

