export default function(tipoPreco){
    if(typeof tipoPreco != 'undefined'){
        if(tipoPreco == 1){
            return  '^[0-9]'
        }else {
            return '^([0-9]|-)'
        }
    }else{
        return '^([0-9]|-)'
    }
    
}