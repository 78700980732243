import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VeeValidate, { Validator } from "vee-validate";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import CpfValidator from './rules/cpf.validator'
import CnpjValidator from './rules/cnpj.validator'
import VueTheMask from 'vue-the-mask'
import axios from "axios"
import 'sweetalert2/src/sweetalert2.scss'
import store from "./store/index.js"
const moment = require('moment')
require('moment/locale/pt-br')

//UTEIS
import InputFormat from './uteis/FormatMoney'
import CompleteDecimal from './uteis/CompleteDecimal'
import ValueNotNegative from './uteis/ValueNotNegative'
import VueNativeNotification from 'vue-native-notification'


Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueTheMask)
Vue.use(require('vue-moment'),{moment});

Validator.extend('cpf', CpfValidator)
Validator.extend('cnpj', CnpjValidator)
Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: "veeFields",
  errorBagName: "veeErrors",
  classes: true,
  classNames: {
    valid: 'valid',
  }
});

Vue.use(VueNativeNotification, {
  requestOnNotify: true
})

var url = null

if(window.location.href.includes('energia.app.br')){
    //PRODUÇÃO
    url = 'https://energia.app.br/skynet-api/public/'
}

if(window.location.href.includes('skynet-dev')){
    //HOMOLOGAÇÃO
    url = 'https://energia.app.br/homo/skynet-api/public/'
}

if(window.location.href.includes('localhost')){
    //DEV LOCAL
    url = 'http://localhost/dev/skynet-api/public/'
}

Vue.prototype.$urlApi = url+'uploads'
Vue.prototype.$urlApiMinuta = url+'minutas'
Vue.prototype.$urlApiContratos = url+'contratos'
Vue.prototype.$urlNotaFiscal = url+'notasFiscais/'
Vue.prototype.$urlRelatorios = url+'relatorios/'
Vue.prototype.$urlDri = url+'dri/'
Vue.prototype.$http = axios.create({
  baseURL: url+'api',
  headers: {
      'Content-Type': 'application/json',
      'Authorization' : localStorage['tokenSkopos'],
      'rememberToken' : localStorage['rememberToken'] ?? 0,
      'ngrok-skip-browser-warning' : true
  }
})

Vue.filter('toCurrency', function (value) {
  if (typeof value !== "number") {
      return value;
  }
  var formatter = new Intl.NumberFormat('de-DE', {
    maximumFractionDigits: 2
  });
  return formatter.format(value);
});

Vue.filter('toCurrencyNo', function (value) {
  if (typeof value !== "number") {
      return value;
  }
  var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'BRL'
  });
  return formatter.format(value).replace(' R$','');
});

Vue.filter('formatNumber', function (value,decimal) {
  var formatter = new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: decimal
  });
  if(value != null && value != ''){
    return formatter.format(value).replace(' R$','');
  }else{
    return value;
  }
});

Vue.filter('getSeletor', function (value,seletor,seletorIndex) {
  var result  = seletor[seletorIndex].find(item => item.value == value )
  return result ? result.text : ''
});

Vue.prototype.$formatBaseMoney = function(value, decimal = 2){
  var formatter = new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: decimal
  });
  if(value != null && value != ''){
    return formatter.format(value).replace(' R$','');
  }else{
    return value;
  }
}

Vue.prototype.$isAccess = function(value){
  if(typeof this.$store.getters.getAccess[value] != 'undefined'){
    if(this.$store.getters.getAccess[value].edicao || this.$store.getters.getAccess[value].leitura){
      return true
    }else{
      return false
    }
  }else{
    return false
  }
}

Vue.prototype.$isAccessEdit = function(value){
  if(typeof this.$store.getters.getAccess[value] != 'undefined'){
    if(this.$store.getters.getAccess[value].edicao ){
      return true
    }else{
      return false
    }
  }else{
    return false
  }
}

Vue.prototype.$isAccessAdmin = function(){
  if(typeof this.$store.getters.getAccess['admin'] != 'undefined'){
    if(this.$store.getters.getAccess['admin'].isAdmin ){
      return true
    }else{
      return false
    }
  }else{
    return false
  }
}

Vue.prototype.$isAccessAdminPermission = function(permission){
  if(typeof this.$store.getters.getAccess['admin'] != 'undefined'){
    if(this.$store.getters.getAccess['admin'].acessos[permission] ){
      return true
    }else{
      return false
    }
  }else{
    return false
  }
}


//INTEGRATION UTEIS
Vue.prototype.$isCompleteDecimal = CompleteDecimal
Vue.prototype.$formatMoney = InputFormat
Vue.prototype.$valueNotNegative = ValueNotNegative

Vue.prototype.$isAccessCategoria = function(index){

  if(typeof this.$store.getters.getAccess.categorias[index] !== 'undefined'){
    if(this.$store.getters.getAccess.categorias[index] === true){
      return true
    }
  }
  return false
}

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

